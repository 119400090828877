import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDlSGIzBrvvVW7UCyNQIFl7yJ_VDl7CYkE",
  authDomain: "livesfmusic-bbdd1.firebaseapp.com",
  databaseURL: "https://livesfmusic-bbdd1.firebaseio.com",
  projectId: "livesfmusic-bbdd1",
  storageBucket: "livesfmusic-bbdd1.appspot.com",
  messagingSenderId: "176313013319",
  appId: "1:176313013319:web:8613931140a9614d64cd62",
  measurementId: "G-DWHBM461P9"
};

firebase.initializeApp(firebaseConfig);
export default firebase;