import React, {Component, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Moment from 'react-moment';
import moment from 'moment';
import firebase from '../firestore/Firestore';
import './createEvent.css';

const db = firebase.firestore();


class CreateFeaturedArtist extends Component {
  constructor(props) {
    super(props);
    let now = moment.now();
    let date = moment(now).format('YYYY-MM-DDTHH:MM')
    this.state = { 
      event: {
        dateTimestamp: '',
        dateFormatted: '',
        band: '',
        genre: '', 
        venue: '',
        address: '',
        price: 'Free',
        date: date,
        link: '',
        bandDescription: '',
        venueDescription: ''
      },
      featuredVenues: [],
      featuredArtists: []
    }

    this.useStyles = this.useStyles.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.selectVenue = this.selectVenue.bind(this);
    this.selectBand = this.selectBand.bind(this);
    this.handleDate = this.handleDate.bind(this);
  }

  useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  componentDidMount() {
    const venues = db.collection('venues');
    const artists = db.collection('artists');

    let featuredVenues = this.state.featuredVenues;
    let featuredArtists = this.state.featuredArtists;

    venues.get()
      .then(snaps => {
        snaps.forEach(venue =>{
          featuredVenues.push(venue.data());
        })
        this.setState({featuredVenues: featuredVenues},()=>{
          artists.get()
          .then(snaps => {
            snaps.forEach(artist =>{
              featuredArtists.push(artist.data());
            })
            this.setState({featuredVenues: featuredVenues})
          })
        })
      })
  }

  handleChange (e, target) {
    let value = e.target.value;
    let newState = this.state.event;
    newState[target] = value;
    this.setState({event: newState})
  }

  handleDate(e) {
    let value = e.target.value;
    let updateDate = this.state.event;
    let dateFormatted = moment(value).format('llll');
    let dateTimestamp = moment(value).format('x')
    updateDate.date = value;
    updateDate['dateFormatted'] = dateFormatted;
    updateDate['dateTimestamp'] = dateTimestamp;

    this.setState({event: updateDate})
  }

  createEvent() {
    let event = this.state.event;
    db.collection('events').add({
      band: event.band,
      genre: event.genre,
      venue: event.venue,
      address: event.address,
      price: event.price,
      date: event.date,
      dateTimestamp: event.dateTimestamp,
      dateFormatted: event.dateFormatted,
      link: event.link,
      bandDescription: event.bandDescription,
      venueDescription: event.venueDescription
    }).then(()=>{
      console.log(`event by ${event.band} added`);
      this.setState({event: {band: '', genre: '',  venue: '', address: '', price: '', link: '', bandDescription: '', venueDescription: '', price: 'Free'}},) 
    });  
  }

  selectVenue(e, newValue) {
    let venueName = e.target.innerHTML;
    let featuredVenues = this.state.featuredVenues;
    let updatedEvent = this.state.event;
    featuredVenues.map(venue => {
      if(venue.name == venueName) {
        updatedEvent.venue = venue.name;
        updatedEvent.address = venue.address;
        updatedEvent.venueDescription = venue.description;

        this.setState({event: updatedEvent})
      } else {
        updatedEvent.venue = newValue;
        this.setState({event: updatedEvent})
      }
    })
  }

  selectBand(e, newValue) {
    let artistName = e.target.innerHTML;

    let featuredArtists = this.state.featuredArtists;
    let updatedEvent = this.state.event;
    featuredArtists.map(artist => {
      if(artist.name == artistName) {
        updatedEvent.band = artist.name;
        updatedEvent.genre = artist.genre;
        updatedEvent.bandDescription = artist.description;

        this.setState({event: updatedEvent})
      } else {
        updatedEvent.band = newValue
        this.setState({event: updatedEvent})
      }
    })
  }

  render() { 
    const classes = ()=>{this.useStyles()};
    let state = this.state;
    let bands = this.state.featuredArtists;
    let venues = this.state.featuredVenues;

    return ( 
      <div className='section-container'>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField name="date" id="datetime-local" label="Event Time" type="datetime-local" value={state.event.date} onChange={(e)=>{this.handleDate(e)}} className={classes.textField} InputLabelProps={{ shrink: true, }} />

          <div style={{display: 'flex', width: '86%', boxSizing: 'border-box', margin: '0 auto'}}>
            <Autocomplete
              freeSolo
              id="combo-box-demo"
              options={bands.map(option => option.name)}
              // getOptionLabel={option => option.name}
              onChange={(e, newValue)=>{this.selectBand(e, newValue)}}
              onInputChange={(e)=>{this.selectBand(e, e.target.value)}}
              style={{ width: '95%' }}
              renderInput={params => (
                <TextField {...params} label="Band" variant="outlined" fullWidth />
              )}
            />
          </div>
          <TextField name="description" id="outlined-multiline-static" label="Band description" multiline rows="4" value={state.event.bandDescription} onChange={(e)=>{this.handleChange(e, 'bandDescription')}} variant="outlined" />
          <TextField name="genre" id="outlined-basic" onChange={(e)=>{this.handleChange(e, "genre")}} value={state.event.genre} label="Genre" variant="outlined" /> 

          <div style={{display: 'flex', width: '86%', boxSizing: 'border-box', margin: '0 auto'}}>
            <Autocomplete
              freeSolo
              id="combo-box-demo"
              options={venues.map(venue => venue.name)}
              // getOptionLabel={option => option.name}
              onInputChange={(e)=>{this.selectVenue(e, e.target.value)}}
              onChange={(e, newValue)=>{this.selectVenue(e, newValue)}}
              style={{ width: '95%' }}
              renderInput={params => (
                <TextField {...params} label="Venue" variant="outlined" fullWidth />
              )}
            />
          </div>
          <TextField name="description" id="outlined-multiline-static" label="Venue description" multiline rows="4" value={state.event.venueDescription} onChange={(e)=>{this.handleChange(e, 'venueDescription')}} variant="outlined" />
          <TextField name="address" id="outlined-basic" onChange={(e)=>{this.handleChange(e, "address")}} value={state.event.address} label="Address" variant="outlined" /> 
          <div name="price" style={{position: 'relative'}}>
            <span style={{position: 'absolute',bottom: '32%',left: '5%',color: '#bbb',fontWeight: '100', zIndex: '9', fontSize: '1.5em'}}>$</span>
            <TextField id="outlined-basic" onChange={(e)=>{this.handleChange(e, "price")}} value={state.event.price} label="Price" variant="outlined" /> 
          </div>
          <TextField name="link" id="outlined-basic" onChange={(e)=>{this.handleChange(e, "link")}} value={state.event.link} label="Link" variant="outlined" /> 
        </form>
        <Fab onClick={this.createEvent} style={{position: 'fixed', bottom: '3%', right: '3%'}} color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
     );
  }
}

export default CreateFeaturedArtist;