import React, {Component, Fragment} from 'react';
import './App.css';
// Components
import CreateEvent from './components/createEvent/createEvent';
import CreateFeaturedArtists from './components/createFeaturedArtist/createFeaturedArtist';
import CreateFeaturedVenues from './components/createFeaturedVenue/createFeaturedVenue';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      currentBuild: 'createEvent'
    }
    this.selectBuild = this.selectBuild.bind(this);
  }

  selectBuild(e) {
    let build = e.target.dataset.link;
    this.setState({currentBuild: build})
  }

  render() { 
    let currentBuild = this.state.currentBuild;

    return ( 
      <div className='app-container'>
        <ul className='nav'>
          <li onClick={(e)=>{this.selectBuild(e)}} className={currentBuild === 'createEvent' ? 'active' : ''} data-link='createEvent'> Event </li>
          <li onClick={(e)=>{this.selectBuild(e)}} className={ currentBuild === 'createFeaturedArtists' ? 'active' : ''} data-link='createFeaturedArtists'> Artists </li>
          <li onClick={(e)=>{this.selectBuild(e)}} className={currentBuild === 'createFeaturedVenues' ? 'active' : ''} data-link='createFeaturedVenues'> Venues </li>
        </ul>
        {
          currentBuild === 'createEvent'
            ? <CreateEvent />
            : currentBuild === 'createFeaturedArtists'
              ? <CreateFeaturedArtists />
              : currentBuild === 'createFeaturedVenues'
                ? <CreateFeaturedVenues />
                : null
        }
      </div>
     );
  }
}
 

export default App;
