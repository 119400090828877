import React, {Component, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// import Moment from 'react-moment';
import firebase from '../firestore/Firestore';

import './createFeaturedVenue.css';


class CreateFeaturedVenue extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      name: '',
      address: '',
      link: '',
      description: ''
    }

    this.useStyles = this.useStyles.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addFeaturedVenue = this.addFeaturedVenue.bind(this);
  }

  useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  handleChange (e, target) {
    let value = e.target.value;

    this.setState({[target]: value})
  }

  addFeaturedVenue () {
    const db = firebase.firestore();

    db.collection('venues').add({
      name: this.state.name,
      address: this.state.address,
      link: this.state.link,
      description: this.state.description
    }).then(()=>{
      console.log(`venue ${this.state.name} added`);
      this.setState({name: '', address: '', link: '', description: '',})
    })  
  }

  render() { 
    const classes = ()=>{this.useStyles()};
    let state = this.state;

    return ( 
      <div className='section-container'>
        <form className={classes.container} noValidate autoComplete="off">
          <div style={{display: 'flex', width: '86%', boxSizing: 'border-box', margin: '0 auto'}}>
          </div>
{/*venu*/}  <TextField id="outlined-basic" onChange={(e)=>{this.handleChange(e, "name")}} value={state.name} label="Name" variant="outlined" /> 
{/*adre*/}  <TextField id="outlined-basic" onChange={(e)=>{this.handleChange(e, "address")}} value={state.address} label="Address" variant="outlined" /> 
{/*link*/}  <TextField id="outlined-basic" onChange={(e)=>{this.handleChange(e, "link")}} value={state.link} label="Link" variant="outlined" /> 
{/*desr*/}  <TextField id="outlined-multiline-static" label="Multiline" multiline rows="4" value={state.description} onChange={(e)=>{this.handleChange(e, 'description')}} variant="outlined" />
        </form>
        
        <Fab style={{position: 'fixed', bottom: '3%', right: '3%'}} color="primary" aria-label="add">
          <AddIcon onClick={this.addFeaturedVenue} />
        </Fab>
      </div>
     );
  }
}
 
export default CreateFeaturedVenue;